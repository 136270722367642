import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
// import { Provider } from 'react-redux'
// import tenOhThreeStore from './Store/tenOhThreeStore'
// import StateStore from './TenOhThree/Store/StateStore.js'
import TenOhThreeStore from "./AN3/Store/StateStore";

// import 'babel-polyfill'
import "core-js/stable";
import "regenerator-runtime/runtime";
import "./assets/playStylesheets/partner.css";
import "bootstrap/dist/css/bootstrap.css";
import "./App.css";
import "@fortawesome/fontawesome-free/css/all.css";
// import store from "./Store/index";
// import { TransitionGroup, CSSTransition } from 'react-transition-group'
import { setCSRF } from "./Store/Actions/applyNowActions";
import $ from "jquery";

import asyncComponent from "./assets/Components/AsyncComponents/AsyncComponent";
import GlobalStateStore from "./Store/GlobalStateStore";
// import LandingPage from './LandingPage/LandingPage';
// import TenOhThree from './TenOhThree/TenOhThree.js'
// import BankAccountRequests from './BankAccountRequests/BankAccountRequests'
// import FourOhFour from './FourOhFour/FourOhFour'
import { GlobalContext } from "./Store/GlobalContext";
import { MortgageCalcContext } from "./Store/MortgageCalcContext";

import AirBrake from "./assets/Components/Airbrake/Airbrake";
import { removeURLParameter } from "./assets/utils/queryParamCleaner";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faShareSquare,
  faLock,
  faUser,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { faCopy } from "@fortawesome/free-regular-svg-icons";

library.add(faLock, faUser, faShareSquare, faCopy, faTimes);

const AsyncLandingPage = asyncComponent(() =>
  import("./LandingPage/LandingPage")
);
const AsyncAN3 = asyncComponent(() => import("./AN3/AN3.js"));
const AsyncBankAccountRequests = asyncComponent(() =>
  import("./BankAccountRequests/BankAccountRequests")
);
const AsyncFourOhFour = asyncComponent(() => import("./FourOhFour/FourOhFour"));
// const AsyncLoginPage = asyncComponent( () => import("./LoginPage/LoginPage") );
const AsyncResetPasswordPage = asyncComponent(() =>
  import("./ResetPassword/ResetPassword")
);
const AsyncForgotPasswordPage = asyncComponent(() =>
  import("./ForgotPassword/ForgotPassword")
);
// const AsyncWelcomePage = asyncComponent(() => import("./Welcome/Welcome"))
const AsyncHomeOwnersInsurance = asyncComponent(() =>
  import("./HomeownersInsurance/HomeownersInsurance.tsx")
);
const AsyncExpiredLink = asyncComponent(() =>
  import("./ExpiredLink/ExpiredLink")
);
const AsyncHabitatComponents = asyncComponent(() =>
  import("./habitat/redesign/HabitatComponents")
);
const AsyncIRWaterfall = asyncComponent(() =>
  import("./IRWaterfall/IRWaterfall")
);

// const AsyncESign = asyncComponent(() => import("./ESign/ESign"))
// const AsyncEsignConsole = asyncComponent(() => import("./EsignConsole/EsignConsole"))
//This is only for custom code lenders are importing in. We shouldn't use jquery in the react code.

if (!window.$) {
  window.$ = $;
}

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      bankAccountRequestsLoanId: "",
      bankAccountRequestsDocId: "",
      coBranding: "",
    };
  }

  componentDidMount() {
    this.setState({
      coBranding: window.PARAMS ? window.PARAMS.partnerAlias : "",
    });
  }

  initialRedirect() {
    let component = "";
    let queryParams = window.location.search ? window.location.search : "";
    queryParams = removeURLParameter(queryParams, "forwardToSection");
    queryParams = removeURLParameter(queryParams, "preview");
    let path = window.location.pathname + queryParams;
    if (window.APP_ID) {
      component = window.APP_ID;
      if (this.state.coBranding) {
        return <Redirect to={`/r/${this.state.coBranding}${queryParams}`} />;
      }
    }

    if (path === `/${queryParams}` || component === "LandingPage") {
      return <Redirect to={`/${queryParams}`} />;
    } else if (
      path.indexOf("/company/landingPage/edit") > -1 ||
      component === "LandingPageEdit"
    ) {
      return <Redirect to={`/company/landingPage/edit`} />;
    } else if (path.indexOf("/expired-link") > -1) {
      return <Redirect to={`/expired-link`} />;
    } else if (
      path.indexOf("/r/") > -1 ||
      component === "LandingPageCoBranded"
    ) {
      return <Redirect to={`/r/${this.state.coBranding}${queryParams}`} />;
    } else if (path.indexOf("/n/apply") > -1 || component === "ApplyNow") {
      return <Redirect to={`/n/apply${queryParams}`} />;
    } else if (
      path.indexOf("bankAccountRequests") > -1 ||
      component === "BankAccounts"
    ) {
      if (
        this.state.bankAccountRequestsLoanId === "" &&
        this.state.bankAccountRequestsDocId === ""
      ) {
        this.bankAccountRequestsParams();
      }
      return (
        <Redirect
          to={`/loans/${this.state.bankAccountRequestsLoanId}/bankAccountRequests/${this.state.bankAccountRequestsDocId}`}
        />
      );
    } else if (path.indexOf("an3") > -1 || component === "AN3") {
      return <Redirect to={`/n/an3${queryParams}`} />;
    } else if (path.indexOf("prospects") > -1 || component === "1003Editor") {
      return <Redirect to={`/prospects/loan-app${queryParams}`} />;
    } else if (
      path.indexOf("/loan/app/loan-app") > -1 ||
      component === "LoanEditor"
    ) {
      return <Redirect to={`/loan/app/loan-app${queryParams}`} />;
    } else if (path.indexOf("/apply-now") > -1) {
      return <Redirect to={`/apply-now${queryParams}`} />;
    } else if (path.indexOf("/login") > -1) {
      return <Redirect to={`/login${queryParams}`} />;
    } else if (path.indexOf("/verify-user") > -1) {
      return <Redirect to={`${path}`} />;
    } else if (
      path.indexOf("/apply-reset-password") > -1 ||
      path.indexOf("/expired-password/") > -1
    ) {
      return <Redirect to={`${path}`} />;
    } else if (path.indexOf("/apply-forgot-password") > -1) {
      return <Redirect to={`${path}`} />;
    } else if (path.indexOf("/esign-form") > -1) {
      return <Redirect to={`${path}`} />;
    } else if (path.indexOf("/e-sign") > -1) {
      return <Redirect to={`${path}`} />;
    } else if (path.includes("homeowners-insurance")) {
      return <Redirect to={path} />;
    } else if (path.includes("/n/habitat")) {
      return <Redirect to={path} />;
    } else if (path.includes("ir-waterfall")) {
      return <Redirect to={path} />;
    } else {
      return <Redirect to="/404" />;
    }
  }

  bankAccountRequestsParams() {
    if (window.PARAMS) {
      this.setState({
        bankAccountRequestsLoanId: window.PARAMS.loanId,
        bankAccountRequestsDocId: window.PARAMS.docId,
      });
    } else {
      // running local, need to get params from url
      let path = window.location.pathname.split("/");
      console.log("running locally");
      this.setState({
        bankAccountRequestsLoanId: path[2],
        bankAccountRequestsDocId: path[4],
      });
    }
  }

  render() {
    return (
      <AirBrake>
        <Router>
          <div className="fullWidthHeight">
            <Route
              render={({ location }) => (
                <div className="fullWidthHeight">
                  <GlobalStateStore>
                    <GlobalContext.Consumer>
                      {(landingPage) => (
                        <MortgageCalcContext.Consumer>
                          {(calcs) => (
                            <Switch location={location}>
                              <Route
                                exact
                                path={`/`}
                                component={AsyncLandingPage}
                              />
                              <Route
                                exact
                                path={`/company/landingPage/edit`}
                                component={AsyncLandingPage}
                              />
                              <Route
                                exact
                                path={`/r/${this.state.coBranding}`}
                                component={AsyncLandingPage}
                              />
                              <Route
                                exact
                                path={`/loans/${this.state.bankAccountRequestsLoanId}/bankAccountRequests/${this.state.bankAccountRequestsDocId}`}
                                render={() => (
                                  <AsyncBankAccountRequests
                                    loanId={
                                      this.state.bankAccountRequestsLoanId
                                    }
                                    docId={this.state.bankAccountRequestsDocId}
                                  />
                                )}
                              />
                              <Route path={"/n/an3"} component={AsyncAN3} />
                              <Route
                                path={"/loan/:loanId/homeowners-insurance"}
                                component={AsyncHomeOwnersInsurance}
                              />
                              <Route
                                path={"/prospects/loan-app"}
                                component={AsyncAN3}
                              />
                              <Route
                                path={"/loan/app/loan-app"}
                                component={AsyncAN3}
                              />
                              <Route
                                path={`/apply-reset-password`}
                                component={AsyncResetPasswordPage}
                              />
                              <Route
                                path={`/expired-password`}
                                component={AsyncResetPasswordPage}
                              />
                              <Route
                                path={`/apply-forgot-password`}
                                component={AsyncForgotPasswordPage}
                              />
                              <Route
                                path={`/expired-link`}
                                component={AsyncExpiredLink}
                              />
                              <Route
                                path={`/loan/:loanId/ir-waterfall`}
                                component={AsyncIRWaterfall}
                              />
                              {process.env.NODE_ENV === "development" && (
                                <Route
                                  path={`/n/habitat`}
                                  component={AsyncHabitatComponents}
                                />
                              )}
                              {/* <Route path={`/esign-form`} component={AsyncESign} /> */}
                              {/* <Route path={`/e-sign`} component={AsyncEsignConsole} /> */}
                              {/* <Route path={`/verify-user`} component={AsyncWelcomePage} /> */}
                              {/* <Route path={'/apply-now'} component={AsyncLoginPage} />
                                <Route path={'/login'} component={AsyncLoginPage} /> */}
                              <Route path="/404" component={AsyncFourOhFour} />
                            </Switch>
                          )}
                        </MortgageCalcContext.Consumer>
                      )}
                    </GlobalContext.Consumer>
                  </GlobalStateStore>

                  {(() => {
                    return this.initialRedirect();
                  })()}
                </div>
              )}
            />
          </div>
        </Router>
      </AirBrake>
    );
  }
}

export default App;